/* MyCarousel.css */
.custom-carousel {
  width: 100%;
  max-width: 900px;
  margin: auto;
  overflow: hidden; /* Ensure images don't overflow the container */
}

.custom-carousel .carousel-image {
  width: 100%;
  height: auto;
  object-fit: cover;
}

/* Optionally, you can add additional styling for a modern look */
.custom-carousel .carousel-inner {
  border-radius: 10px; /* Add border-radius for a rounded appearance */
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2); /* Add a subtle shadow for depth */
}

.custom-carousel .carousel-control-prev, .custom-carousel .carousel-control-next {
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background for control buttons */
  border-radius: 50%; /* Make control buttons circular */
}

.custom-carousel .carousel-control-prev-icon, .custom-carousel .carousel-control-next-icon {
  filter: brightness(200%); /* Increase brightness for better visibility */
}

/* Optionally, you can add more styling based on your design preferences */

